<template>
  <section class="podcast" ref="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 header">
          <HeadlineTitleText :item="item"></HeadlineTitleText>
        </div>
      </div>
    </div>
    <div class="slider-wrapper" v-if="list">
      <Observer name="fade-up">
        <hooper :settings="hooperSettings" @slide="updateProgress" @updated="updateSlider" tabindex="-1" :class="{'no-slide': list.length < itemsToShow}" ref="carousel">
          <slide v-for="(card, j) in list" :key="'key-' + j" :index="j">
            <div class="preview">
              <div class="visual" v-if="card.image || card.image_url">
                <img v-if="card.image_url" :src="card.image_url" :alt="card.title">
                <lazy-picture v-else-if="card.image" :item="card" desktop-ratio="1.6" mobile-ratio="1.6"></lazy-picture>
              </div>
              <audio-player :file="card" :in-card="true" v-if="card">
                <div class="podcast-infos">
                  <div>
                    <span class="podcast-title" v-if="(card.audio_file_url || card.displayTitle) && card.title">{{ card.title }}</span>
                    <span class="podcast-artist" v-if="(card.audio_file_url || card.displayFeaturing && card.featuring)">{{ card.featuring }}</span>
                  </div>
                </div>
              </audio-player>
              <p class="text" v-if="(card.audio_file_url || card.displayDescription && card.description)" v-text="card.description"></p>
            </div>
          </slide>
          <slide v-if="!isMobile && list.length > 3"></slide>
          <div class="hooper-navigation" slot="hooper-addons" v-if="list.length >= itemsToShow">
            <button type="button" class="hooper-prev" :class="{'is-disabled': currentSlide === (itemsToShow === 3.5 ? 1 : 0)}" :aria-label="$t('Previous slide')" @click="() => $refs.carousel.slidePrev()"></button>
            <button type="button" class="hooper-next" :class="{'is-disabled': currentSlide > list.length - itemsToShow + 1}" :aria-label="$t('Next slide')" @click="() => $refs.carousel.slideNext()"></button>
          </div>
          <hooper-progress v-if="list.length > 1" slot="hooper-addons"></hooper-progress>
        </hooper>
      </Observer>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture'
import HeadlineTitleText from './utils/HeadlineTitleText'
import AudioPlayer from '../components/AudioPlayer'
import Podcast from '../utilities/podcast-mixin'
import { Hooper, Slide, Progress as HooperProgress} from 'hooper'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  props: ['item', 'items', 'declination', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook, Podcast],
  name: 'PodcastFashion',
  components: {
    LazyPicture,
    HeadlineTitleText,
    AudioPlayer,
    Hooper,
    Slide,
    HooperProgress
  },
  data () {
    return {
      currentSlide: 1,
      itemsToShow: 3.5,
      hooperSettings: {
        centerMode: true,
        initialSlide: 0,
        itemsToShow: 1.25,
        transition: 400,
        autoPlay: false,
        mouseDrag: false,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        breakpoints: {
          768: {
            itemsToShow: 2.5,
            mouseDrag: false,
            shortDrag: false,
            touchDrag: false,
            initialSlide: 1
          },
          1200: {
            itemsToShow: 3.5,
            initialSlide: 1
          }
        }
      }
    }
  },
  created () {
    this.getData(this.declination)
  },
  mounted () {
    setTimeout(() => {
      if (!this.isMobile) {
        this.$refs.carousel.slideTo(1)
      }
    }, 1000)
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    }
  },
  methods: {
    updateSlider (payload) {
      this.itemsToShow = payload.settings.itemsToShow
    },
    updateProgress (payload) {
      this.currentSlide = payload.currentSlide
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  margin: 4rem 0;

  .header {
    margin-bottom: 8rem;

    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }
}

.slider-wrapper {
  position: relative;
  margin: 0;

  @include bp(max md) {
    margin: 0;
  }

  .fade-enter, .fade-leave-to {
    top: 0;
    left: 0;
  }
}

.hooper {
  margin: 0;
  padding: 0;
  height: auto;
  outline: none;

  &-slide {
    position: relative;
    outline: none;
    height: auto;
    padding: 2rem;

    /deep/ .obs {
      height: 100%;
    }
    /deep/ > .obs {
      transform: translateY(0);

      &.obs-fade-up {
        transform: translateY(10rem);

        @include bp(max md) {
          opacity: 1;
          transform: none;
        }
      }
    }

    @include bp(max md) {
      &:not(.is-current) {
        .preview {
          .text, .player-audio {
            opacity: 0;
          }
        }
      }
    }
  }

  &.no-slide {
    /deep/ .hooper-track {
      transform: none !important;
      justify-content: center;
    }
  }
}

.preview {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .obs {
    height: auto;
  }

  .lazy-picture {
    margin-bottom: 3rem;

    @include bp(max md) {
      margin-bottom: 2rem;
    }

    /deep/ img {
      width: 100%;
    }
  }

  .text {
    margin-bottom: 0;
    color: var(--zenith-grey);
    font-weight: 400;
    font-size: em(14);
    line-height: em(22, 14);
    transition: all 0.2s ease-in-out;

    @include bp(max md) {
      font-size: em(12);
      line-height: normal;
    }
  }
}

.podcast {
  &-title {
    display: block;
    font-weight: 500;
    font-size: em(14);

    @include bp(max md) {
      font-size: em(12);
    }
  }

  &-artist {
    display: block;
    color: var(--zenith-grey);
    font-weight: 400;
    font-size: em(14);
    margin-bottom: 2rem;

    @include bp(max md) {
      font-size: em(12);
    }
  }
}

/deep/ .hooper-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 200;
  transform: translate(-50%, -50%);

  @include bp(max md) {
    display: none;
  }

  .hooper-prev {
    left: 4rem;
    outline: none;
    &:before {
      content: "\e903";
    }
    &:hover {
      &:before {
        @include pillPrevHover();
      }
    }
  }
  .hooper-next {
    right: 4rem;
    outline: none;
    &:before {
      content: "\e925";
    }
    &:hover {
      &:before {
        @include pillNextHover();
      }
    }
  }

  button {
    opacity: 0.5;
    padding: 1.3rem;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    background: #f8f8f8;

    svg {
      display: none;
    }

    &:before {
      display: block;
      font-family: 'zenith', sans-serif !important;
      speak: none;
      font-size: em(20);
      line-height: 2rem;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.is-disabled {
      opacity: 0 !important;
      pointer-events: none;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}

/deep/ .hooper-progress {
  position: relative;
  background: #cccccc;
  height: .2rem;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @include bp(md) {
    display: none;
  }

  &-inner {
    background: #000;
  }
}

</style>
